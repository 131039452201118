body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error-message {
  color: red;
}

.SpinnerWrapper {
  display: block;
  position: fixed;
  z-index: 1031;
  /* High z-index so it is on top of the page */
  top: 50%;
  width: 100%;
  text-align: center;
}

.spinner-border-l {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.SpinderCaption {
  font-size: 18px;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  color: #007bff;
  font-size: 10px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  /* text-indent: -9999em; */
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader-overlay.full-loader {
  z-index: 99999;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.share-icon,
.move-iocn,
.move-icon {
  z-index: 1 !important;
}

.branding-links-wrap .branding-list {
  height: 300px;
  overflow-y: auto;
  margin-top: 15px;
}
.hashtag-wrappr {
  max-height: 103vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.d-flex {
  display: flex !important;
}
.justify-content-center {
  justify-content: center !important;
}

.color-3B3B3B {
  color: #3B3B3B !important;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}
.social-login {
  display: flex;
  justify-content: space-between;
}
hr.dashed {
  border-top: 1px dashed black;
}

.noti-block .noti-description{
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  padding: 5px 25px;
  margin-left: 60px;
  /* font-weight: bold; */
  /* border-left: 1px solid #ffffff; */
}

.close-icon {
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  padding: 5px 25px;
  margin-left: 60px;
}

.checkbox-container {
  display: block;
  position: absolute;
  padding-left: 35px;
  margin-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #e5e5e5;
  border: solid #fb6051;
}



/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #fb6051;
  border: solid white;
  border-width: 1px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.postNote{
  color: #28C2E4;
}

.footer{
  background-color:#2E4050;
  position: sticky;
  bottom: 0;
  z-index: 100;
  width: 100%;
}

.socilaFollow{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 300px;
}
@media (max-width: 575px) {
  .socilaFollow {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px;
  }
}

.users-list .active{
 border: 5px solid rgb(250 175 64); 
 border-radius :50%;
}